.App {
/*  text-align: center; */
}

.App .ant-layout-header {
    background: white;    
}

.App .logo {
  height: 64px;
  line-height: 64px;
  float: left;    
}

.login-modal .ant-modal-footer {
    text-align: center;
}
.login-modal .ant-modal-body {
    text-align: center;
    font-size: 20px;
}

table ul {
    padding-left: 1rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

